<template>

  <div class="Panel bg-white">

    <NavBar
        :user="user"
        :context="context"
    />

    <div class="">

      <b-row>

        <b-col cols="12" lg="2" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">

          <b-list-group>
            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/clients'">
              <span class="material-symbols-outlined align-middle fw-600 icon">group</span>
              Klienci
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/payments'">
              <span class="material-symbols-outlined align-middle fw-600 icon" style="color:#585bff;">credit_score</span>
              Płatności
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/deliveries'">
              <span class="material-symbols-outlined align-middle fw-600 icon">local_shipping</span>
              Wysyłki
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">list_alt</span>
              Faktury
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">person_remove</span>
              Usuwanie danych
            </b-list-group-item>

            <b-list-group-item style="border:0px;border-bottom: 1px solid #ddd;background-color: #f9f9f9;">

            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">procedure</span>
              Procedury
            </b-list-group-item>


          </b-list-group>

        </b-col>

        <b-col cols="12" lg="10" class="p-3 pt-4">

          <h3>#{{ payment.id }} / {{ getPaymentItem(payment.item).name }}</h3>

          <hr/>
          <b-row class="d-flex">

            <b-col cols="12" lg="2" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">
              <b-button variant="light" @click="(selectedPage = 'data')" :class="{ active: (selectedPage === 'data') }" block>Dane płatności</b-button>
              <b-button variant="light" @click="(selectedPage = 'permissions')" :class="{ active: (selectedPage === 'permissions') }" block>Uprawnienia</b-button>
            </b-col>

            <b-col cols="12" lg="10" class="p-3">

              <div v-if="selectedPage === 'data'">

                <B style="font-size:18px;">Edycja danych</B><br/><br/>

                Nazwa
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="saveName()" v-model="itemNameText"></b-form-input>

                Kwota (PLN)
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updatePaymentData('amount')" v-model="payment.amount"></b-form-input>

                Data zlecenia (UTC)
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updatePaymentData('date_time')" v-model="payment.date_time"></b-form-input>

                Data potwierdzenia (UTC)
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updatePaymentData('confirmed_at')" v-model="payment.confirmed_at"></b-form-input>

                Data ważności (UTC)
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updatePaymentData('valid_to')" v-model="payment.valid_to"></b-form-input>

                Metoda płatności
                <b-form-select class="w-100 mb-2" @change="updatePaymentData('method')" v-model="payment.method" :options="methodOptions"></b-form-select>

                Status
                <b-form-select class="w-100 mb-2" @change="updatePaymentData('status')" v-model="payment.status" :options="statusOptions"></b-form-select>

                Status faktury
                <b-form-select class="w-100 mb-2" @change="updatePaymentData('invoice_status')" v-model="payment.invoice_status" :options="invoiceStatusOptions"></b-form-select>

                <span v-if="payment.voluntary_consent">
                  <br/><br/>
                  <b-alert show variant="warning">{{ payment.voluntary_consent }}</b-alert>
                </span>

                <br/><br/>

                <span v-if="payment.delivery_data">

                  <B style="font-size:18px;">Dane dostawy</B><br/><br/>

                  <span>
                    <b-alert show variant="warning">
                      <pre>{{ payment.delivery_data_json }}</pre>
                    </b-alert>
                  </span>

                  <br/>

                </span>



                <B style="font-size:18px;">Zrzut danych</B><br/><br/>

                <pre>{{ payment }}</pre>

              </div>


              <div v-if="selectedPage === 'permissions'">

                <b-row class="d-flex">

                  <b-col cols="6" lg="6">

                    <B style="font-size:18px;">Edycja uprawnień dla płatności</B><br/><br/>

                    <b-form-textarea rows=20 v-model="itemPermissionsText"></b-form-textarea>

                    <br/>
                    <b-button variant="light" block @click="savePermissions()">Zapisz</b-button>

                  </b-col>

                  <b-col cols="6" lg="6">

                    <B style="font-size:18px;">Pobierz uprawnienia z pakietu</B><br/><br/>

                    <b-form-select @change="selectedItemUpdate" v-model="selectedItem" :options="itemsForSelect"></b-form-select>

                    <b-form-textarea rows=20 v-model="selectedItemText" @click="$event.target.select()" readonly></b-form-textarea>

                  </b-col>

                </b-row>

              </div>

            </b-col>

          </b-row>

        </b-col>

      </b-row>

    </div>

    <Footer/>

  </div>

</template>

<script>

import Footer from '@/elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {

  name: 'Panel',

  components: {
    NavBar, Footer
  },

  data() {

    return {
      context: null,
      selectedPage: 'data',
      payment: {
        id: null
      },
      user: {
        id: null,
        permissions: []
      },
      invoiceStatusOptions: [
        { value: '0', text: 'Oczekuje na określenie' },
        { value: '1', text: 'Określona, oczekuje na wystawienie' },
        { value: '2', text: 'Określona jako niefakturowana' },
        { value: '3', text: 'Pominięta' },
        { value: '4', text: 'Wystawiona' }
      ],
      statusOptions: [
        { value: '', text: '' },
        { value: 'OK', text: 'OK (potwierdzona)' }
      ],
      methodOptions: [
        { value: 'p24', text: 'p24' },
        { value: 'P24 Card charge', text: 'P24 Card charge' },
        { value: 'payu', text: 'payu' },
        { value: 'imoje', text: 'imoje' },
        { value: 'FREE', text: 'FREE' },
        { value: 'payu-loan', text: 'payu-loan' },
        { value: 'payu-po', text: 'payu-po' },
        { value: 'paypal', text: 'paypal' },
        { value: 'imoje-recu', text: 'imoje-recu' },
        { value: 'ING Card charge', text: 'ING Card charge' }
      ],
      itemPermissions: [],
      items: [],
      itemsForSelect: [],
      selectedItem: '',
      selectedItemText: '',
      itemNameText: ''
    }

  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

        this.axios.get(window.API_URL + "manager/getContext", { withCredentials: true }).then((response) => {

          this.context = response.data.context

          if(this.$route.params.id && this.$route.params.token) {

            this.load(this.$route.params.id, this.$route.params.token)

          }

        })

      }

    });

  },

  computed: {
    itemPermissionsText: {
      get() {
        return this.itemPermissions?.join('\n')
      },
      set(newValue) {
        this.itemPermissions = newValue.split('\n').map(e => e.trim()).filter(e => e != null && e !== '')
      }
    }

  },

  methods: {

    load: function (paymentId, token){

      if(paymentId && token) {

        this.axios.post(window.API_URL + "payments/getById", { 'id': paymentId, 'token': token}, { withCredentials: true }).then((response) => {

          this.payment = (response.data)
          this.payment.token = token

          if(this.payment.delivery_data) {
            this.payment.delivery_data_json = JSON.parse(this.payment.delivery_data)
          }

          this.itemPermissions = JSON.parse(this.payment.item).permissions

          this.itemNameText = JSON.parse(this.payment.item).name

          this.axios.get(window.API_URL + "payments/getPermissionsFromItems", { withCredentials: true }).then((response) => {

            let tmp = JSON.parse(JSON.stringify(response.data));

            tmp.forEach((item) => {

              this.items.push(JSON.parse(item.data))

              var obj = {
                text: JSON.parse(item.data).name,
                value: JSON.parse(item.data).permissions
              };

              this.itemsForSelect.push(obj)

            });


          })

        })

      }

    },
    saveName: function () {

      var tmp_txt = this.itemNameText

      tmp_txt = tmp_txt.replaceAll("'", "");
      tmp_txt = tmp_txt.replaceAll("[", "");
      tmp_txt = tmp_txt.replaceAll("]", "");
      tmp_txt = tmp_txt.replaceAll('"', "");
      tmp_txt = tmp_txt.replaceAll(',', "");

      var tmp_item = JSON.parse(this.payment.item)

      tmp_item.name = tmp_txt

      this.updatePaymentData('item', JSON.stringify(tmp_item))

      this.payment.item = JSON.stringify(tmp_item)

    },

    savePermissions: function () {

      var tmp_txt = this.itemPermissionsText

      tmp_txt = tmp_txt.replaceAll("'", "");
      tmp_txt = tmp_txt.replaceAll("[", "");
      tmp_txt = tmp_txt.replaceAll("]", "");
      tmp_txt = tmp_txt.replaceAll('"', "");
      tmp_txt = tmp_txt.replaceAll(',', "");

      var tmp_item = JSON.parse(this.payment.item)

      tmp_item.permissions = tmp_txt.split("\n")

      this.updatePaymentData('item', JSON.stringify(tmp_item))

      this.payment.item = JSON.stringify(tmp_item)
    },

    updatePaymentData: function (field, overrideValue = null) {

      if(field) {

        this.axios.get(window.API_URL + "manager/getContext", { withCredentials: true }).then((response) => {

          let context = response.data.context

          var contextText = ""
          var changesValue = ""

          if(context === 1) { contextText = "funnelstar.io" }
          if(context === 2) { contextText = "edunso.pl" }

          changesValue = this.payment[field]

          if(overrideValue)
            changesValue = overrideValue

          if (confirm("Zmieniasz pole " + field + " na " + changesValue + " w kontekscie " + contextText) == true) {

            this.axios.post(window.API_URL + "payments/updateData", { 'id': this.payment.id, 'token': this.payment.token, 'field': field, 'value': changesValue}, { withCredentials: true }).then((response) => {

              this.$bvToast.toast('Zwrócony wynik zmiany pola ' + field + ' to: ' + response.data[field], {
                title: 'Sprawdź wynik',
                autoHideDelay: 5000,
                appendToast: true,
                solid: true,
                variant: 'warning'
              })

            })

          }


        });

      }

    },

    selectedItemUpdate: function () {

      this.selectedItemText = '';

      this.selectedItem.forEach((item) => {

        this.selectedItemText += item + "\n"

      })

    },

    getPaymentItem: function (itemJson) {

      let item = JSON.parse(itemJson);

      return item;

    }


  }

}

</script>

<style>
  .pointer {
    cursor: pointer;
  }
</style>