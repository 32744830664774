<template> 
 <div class="bg-blue-dark NavBar">
  <b-navbar toggleable="lg" class="pl-4 bg-blue-dark" type="dark">
    <b-navbar-brand :to="'/'">
      <span class="material-symbols-outlined align-middle icon-logo">manufacturing</span>
      <small v-if="(context === 0 || context === null || context === 1)"> Funnelstar.io</small>
      <small v-if="(context === 2)"> Edunso.pl</small>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <span class="text-blue-light">Zmień kontekst</span>
          </template>
          <b-dropdown-item v-on:click="setContext(1)">Funnelstar.io</b-dropdown-item>
          <b-dropdown-item v-on:click="setContext(2)">Edunso.pl</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- <b-navbok
      ar-nav>
        <b-nav-item-dropdown right>
          <template #button-content>
            Usługi
          </template>
          <b-dropdown-item :href="'https://app.funnelstar.io/services/seo-links'">Katalogowanie</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav> -->


      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <!--<b-navbar-nav>
           <b-nav-item :to="'/achievements'"><span class="">Osiągnięcia</span></b-nav-item>
          <b-nav-item :to="'/generator'">Kontakt</b-nav-item>
        </b-navbar-nav>-->

        <b-nav-item :to="'/panel'" v-if="!user.id">Zaloguj</b-nav-item>

        <b-nav-item-dropdown right v-if="user.id">
          <template #button-content>
            <span class="material-symbols-outlined align-middle fw-100 icon" style="font-size: 22px;color: #a0a2fc;" >people</span> Strefa klienta
          </template>
          <b-dropdown-item :to="'/panel'">Produkty</b-dropdown-item>
          <b-dropdown-item :href="'https://login.edunso.pl/'">Ustawienia</b-dropdown-item>
          <b-dropdown-item :href="'https://login.edunso.pl/logout'">Wyloguj</b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    user: {
      id: 22,
      permissions: []
    },
    context: null
  },
  methods: {

    setContext: function(context) {

      this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        if(data.userId) {

          this.axios.post(window.API_URL + "manager/setContext", { 'context': context}, { withCredentials: true }).then(() => {

            window.location.reload()

          })


        }

      });

    }
  }
}
</script>

 