<template>

  <div class="Panel bg-white">

    <NavBar
        :user="user"
        :context="context"
    />

    <div class="">

      <b-row>

        <b-col cols="12" lg="2" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">

          <b-list-group>
            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/clients'">
              <span class="material-symbols-outlined align-middle fw-600 icon" style="color:#585bff;">group</span>
              Klienci
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/payments'">
              <span class="material-symbols-outlined align-middle fw-600 icon">credit_score</span>
              Płatności
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/deliveries'">
              <span class="material-symbols-outlined align-middle fw-600 icon">local_shipping</span>
              Wysyłki
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">list_alt</span>
              Faktury
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">person_remove</span>
              Usuwanie danych
            </b-list-group-item>

            <b-list-group-item style="border:0px;border-bottom: 1px solid #ddd;background-color: #f9f9f9;">

            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">procedure</span>
              Procedury
            </b-list-group-item>


          </b-list-group>

        </b-col>

        <b-col cols="12" lg="10" class="p-3 pt-4">

          <h3>#{{ client.id }} / {{ client.first_name }} {{ client.last_name }}</h3>

          <label>Autologin (pamiętaj o czystym trybie incognito): </label>
          <b-form-input @click="$event.target.select()" style="width:500px !important;" v-model="client.autologinLink" readonly></b-form-input>

          <hr/>
          <b-row class="d-flex">

            <b-col cols="12" lg="2" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">
              <b-button variant="light" @click="(selectedPage = 'data')" :class="{ active: (selectedPage === 'data') }" block>Dane konta</b-button>
              <b-button variant="light" @click="(selectedPage = 'payments')" :class="{ active: (selectedPage === 'payments') }" block>Płatności</b-button>
              <b-button variant="light" @click="(selectedPage = 'reccuring')" :class="{ active: (selectedPage === 'reccuring') }" block>Subskrypcje</b-button>
            </b-col>

            <b-col cols="12" lg="10" class="p-3">

              <div v-if="selectedPage === 'data'">

                <B style="font-size:18px;">Akcje</B><br/><br/>

                <b-card class="mb-3">

                  <b-button variant="light" block @click="passwordReset()">Reset hasła</b-button>

                  <div class="mt-2" v-if="newPassword">
                    Nowe hasło: <B><span v-html="newPassword"></span></B>
                  </div>

                </b-card>

                <br/>
                <B style="font-size:18px;">Edycja danych</B><br/><br/>

                E-mail
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updateClientData('email')" v-model="client.email"></b-form-input>

                Imię
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updateClientData('first_name')" v-model="client.first_name"></b-form-input>

                Nazwisko
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updateClientData('last_name')" v-model="client.last_name"></b-form-input>

                Telefon
                <b-form inline>

                  <b-form-input class="mb-2" v-on:keyup.enter="updateClientData('phone_prefix')" v-model="client.phone_prefix"></b-form-input>

                  <b-form-input class="mb-2" v-on:keyup.enter="updateClientData('phone_number')" v-model="client.phone_number"></b-form-input>

                </b-form>

                Nazwa firmy
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updateClientData('company_name')" v-model="client.company_name"></b-form-input>

                Ulica
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updateClientData('street')" v-model="client.street"></b-form-input>

                Miasto
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updateClientData('city')" v-model="client.city"></b-form-input>

                Kod pocztowy
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updateClientData('post_code')" v-model="client.post_code"></b-form-input>

                Kod kraju
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updateClientData('country')" v-model="client.country"></b-form-input>

                TAX ID
                <b-form-input class="w-100 mb-2" v-on:keyup.enter="updateClientData('tax_number')" v-model="client.tax_number"></b-form-input>

                <br/><br/>
                <B style="font-size:18px;">Zrzut danych</B><br/><br/>

                <pre>{{ client }}</pre>

              </div>


              <div v-if="selectedPage === 'payments'">

                <b-table
                    striped
                    hover
                    responsive
                    outlined
                    small
                    class="mt-3"
                    head-variant="light"
                    :items="clientPayments"
                    :fields="clientPaymentsFields"
                >

                  <template #cell(id)="data">
                    <router-link :to="'/panel/payment/' + data.item.id + '/' + data.item.control" target="_blank"><small>{{ data.item.id }}</small></router-link>
                  </template>

                  <template #cell(amount)="data">
                    <small>{{ data.item.amount }} PLN</small>
                  </template>

                  <template #cell(item)="data">
                    <small>{{ getPaymentItemName(data.item.item) }}</small>
                  </template>

                  <template #cell(date_time)="data">
                    <small>
                      <small>
                        Zlecenie: {{ data.item.date_time }}<br/>
                        Potwierdzenie: {{ data.item.confirmed_at }}<br/>
                        Ważność: {{ data.item.valid_to }}
                      </small>
                    </small>
                  </template>

                  <template #cell(status)="data">
                    <small><B>{{ data.item.status }}</B></small>
                  </template>

                  <template #cell(t_id)="data">
                    <small>
                      {{ data.item.control }} <br/>
                      {{ data.item.t_id }} <br/>
                      {{ data.item.method }} <br/>
                    </small>
                  </template>

                </b-table>

              </div>

            </b-col>

          </b-row>

        </b-col>

      </b-row>

    </div>

    <Footer/>

  </div>

</template>

<script>

import Footer from '@/elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {

  name: 'Panel',

  components: {
    NavBar, Footer
  },

  data() {

    return {
      context: null,
      selectedPage: 'data',
      client: {
        id: null
      },
      user: {
        id: null,
        permissions: []
      },
      newPassword: null,
      clientPayments: [],
      clientPaymentsFields: [
        { key: 'id', label: 'PaymentID' },
        { key: 'amount', label: 'Kwota' },
        { key: 'item', label: 'Produkt', thStyle: { width: '150px !important' } },
        { key: 'date_time', label: 'Daty', thStyle: { width: '250px !important' } },
        { key: 'status', label: 'Status' },
        { key: 't_id', label: 'ID Transakcji' }
      ]
    }

  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

        this.axios.get(window.API_URL + "manager/getContext", { withCredentials: true }).then((response) => {

          this.context = response.data.context

          if(this.$route.params.id && this.$route.params.token) {

            this.load(this.$route.params.id, this.$route.params.token)

          }

        })

      }

    });

  },

  methods: {

    load: function (clientId, token){

      if(clientId && token) {

        this.axios.post(window.API_URL + "customers/getById", { 'id': clientId, 'token': token}, { withCredentials: true }).then((response) => {

          this.client = (response.data)
          this.client.token = token

          if(this.context === 1) this.client.autologinLink = "https://id.funnelstar.io/login?al=" + token;
          if(this.context === 2) this.client.autologinLink = "https://login.edunso.pl/login?al=" + token;


          this.axios.post(window.API_URL + "payments/getByClientId", { 'client_id': this.client.id }, { withCredentials: true }).then((response) => {

            this.clientPayments = response.data;

          });


        })

      }

    },

    updateClientData: function (field, overrideValue = null) {

      if(field) {

        this.axios.get(window.API_URL + "manager/getContext", { withCredentials: true }).then((response) => {

          let context = response.data.context

          var contextText = ""
          var changesValue = ""

          if(context === 1) { contextText = "funnelstar.io" }
          if(context === 2) { contextText = "edunso.pl" }

          changesValue = this.client[field]

          if(overrideValue)
            changesValue = overrideValue

          if (confirm("Zmieniasz pole " + field + " na " + changesValue + " w kontekscie " + contextText) == true) {

            this.axios.post(window.API_URL + "customers/updateData", { 'id': this.client.id, 'token': this.client.token, 'field': field, 'value': changesValue}, { withCredentials: true }).then((response) => {

              this.$bvToast.toast('Zwrócony wynik zmiany pola ' + field + ' to: ' + response.data[field], {
                title: 'Sprawdź wynik',
                autoHideDelay: 5000,
                appendToast: true,
                solid: true,
                variant: 'warning'
              })

            })

          }


        });

      }

    },

    passwordReset: function () {

      this.newPassword = Math.random().toString(36).slice(-4);

      this.axios.post(window.API_URL + "customers/makePasswordHash", { 'password': this.newPassword }, { withCredentials: true }).then((response) => {

        var hash = response.data.hash

        this.updateClientData('password', hash);

      });

    },

    getPaymentItemName: function (itemJson) {

      let item = JSON.parse(itemJson);

      return item.name;

    }


  }

}

</script>

<style>
  .pointer {
    cursor: pointer;
  }
</style>