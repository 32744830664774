<template>
 <div>
   <div class="bg-blue-dark Footer">

     <b-row class="container">

     </b-row>

    </div>

 </div>

</template>

<script>
export default {
  name: 'Footer',
  props: {
     
  }
}
</script>

 