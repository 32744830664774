import PanelClients from './pages/Panel/Clients/index';
import PanelClient from './pages/Panel/Client/index';
import PanelPayments from './pages/Panel/Payments/index';
import PanelPayment from './pages/Panel/Payment/index';
import PanelDeliveries from './pages/Panel/Deliveries/index';




export default [

    //{ path: '/', component: Course },
    { path: '/', redirect: '/pakiet/1-mln-ai'},

    //{ path: '/kurs/:slug', component: Course },
    //{ path: '/pakiet/:slug', component: Course },
    //{ path: '/access-denied', component: AccessDenied },

    { path: '/panel', component: PanelClients},
    { path: '/panel/clients', component: PanelClients},
    { path: '/panel/payments', component: PanelPayments},
    { path: '/panel/deliveries', component: PanelDeliveries},

    { path: '/panel/client/:id/:token', component: PanelClient },
    { path: '/panel/payment/:id/:token', component: PanelPayment },

    //{ path: '/panel/kursy', component: PanelCourses},

    //{ path: '/panel/kurs', component: PanelCourse },
    //{ path: '/panel/kurs/:slug', component: PanelCourse },
    //{ path: '/panel/kurs/:slug/:lesson', component: PanelCourse },

    //{ path: '/panel/app/:slug', component: PanelApp },
    //{ path: '/panel/app/:slug/:other', component: PanelApp },



]