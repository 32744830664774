<template>

  <div class="Panel bg-white">

    <NavBar
        :user="user"
        :context="context"
    />

    <div class="">

      <b-row>

        <b-col cols="12" lg="2" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">

          <b-list-group>
            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/clients'">
              <span class="material-symbols-outlined align-middle fw-600 icon" style="color:#585bff;">group</span>
              Klienci
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/payments'">
              <span class="material-symbols-outlined align-middle fw-600 icon">credit_score</span>
              Płatności
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/deliveries'">
              <span class="material-symbols-outlined align-middle fw-600 icon">local_shipping</span>
              Wysyłki
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">list_alt</span>
              Faktury
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">person_remove</span>
              Usuwanie danych
            </b-list-group-item>

            <b-list-group-item style="border:0px;border-bottom: 1px solid #ddd;background-color: #f9f9f9;">

            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">procedure</span>
              Procedury
            </b-list-group-item>


          </b-list-group>

        </b-col>

        <b-col cols="12" lg="10" class="p-3 pt-4">

          <b-form-input class="w-100" v-on:keyup.enter="search" v-model="criteria" placeholder="Szukaj po danych: id, e-mail, nazwisko, nazwa firmy, tax id, numer telefonu ..."></b-form-input>

          <b-row class="d-flex">

            <b-table
                striped
                hover
                responsive
                small
                class="mt-3"
                head-variant="light"
                :items="items"
                :fields="fields"
            >

              <template #cell(id)="data">
                <router-link :to="'/panel/client/' + data.item.id + '/' + data.item.autologin" target="_blank"><small>{{ data.item.id }}</small></router-link>
              </template>

              <template #cell(email)="data">
                <small>{{ data.item.email }}</small>
              </template>

              <template #cell(first_name)="data">
                <small>{{ data.item.first_name }} {{ data.item.last_name }}</small>
              </template>

              <template #cell(company_name)="data">
                <small>
                  {{ data.item.company_name }} <br/>
                  {{ data.item.street }} <br/>
                  {{ data.item.post_code }} {{ data.item.city }} <br/>
                  Kraj: {{ data.item.country }} <br/>
                  TAX ID: {{ data.item.tax_number }} <br/>
                </small>
              </template>

              <template #cell(phone)="data">
                <small>
                  {{ data.item.phone_prefix }} {{ data.item.phone_number }}
                </small>
              </template>

            </b-table>

          </b-row>

        </b-col>

      </b-row>

    </div>

    <Footer/>

  </div>

</template>

<script>

import Footer from '@/elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {

  name: 'Panel',

  components: {
    NavBar, Footer
  },

  data() {

    return {
      context: null,
      user: {
        id: null,
        permissions: []
      },
      criteria: '',
      items: [],
      fields: [
        { key: 'id', label: 'UserID' },
        { key: 'email', label: 'E-mail' },
        { key: 'first_name', label: 'Imię i nazwisko' },
        { key: 'company_name', label: 'Dane do faktur' },
        { key: 'phone', label: 'Telefon' }
      ]
    }

  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

        this.axios.get(window.API_URL + "manager/getContext", { withCredentials: true }).then((response) => {

          this.context = response.data.context

        })

      }

    });

  },

  methods: {

    search: function (){

      if(this.criteria) {

        this.axios.post(window.API_URL + "customers/search", { 'criteria': this.criteria}, { withCredentials: true }).then((response) => {

          this.items = (response.data)

        })

      }

    }

  }

}

</script>