<template>

  <div class="Panel bg-white">

    <NavBar
        :user="user"
        :context="context"
    />

    <div class="">

      <b-row>

        <b-col cols="12" lg="2" class="p-3" style="border-right: 1px solid #e9e9e9;background-color: #f9f9f9;">

          <b-list-group>
            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/clients'">
              <span class="material-symbols-outlined align-middle fw-600 icon" >group</span>
              Klienci
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/payments'">
              <span class="material-symbols-outlined align-middle fw-600 icon" >credit_score</span>
              Płatności
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;color:#585bff;" :to="'/panel/deliveries'">
              <span class="material-symbols-outlined align-middle fw-600 icon">local_shipping</span>
              Wysyłki
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">list_alt</span>
              Faktury
            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">person_remove</span>
              Usuwanie danych
            </b-list-group-item>

            <b-list-group-item style="border:0px;border-bottom: 1px solid #ddd;background-color: #f9f9f9;">

            </b-list-group-item>

            <b-list-group-item style="border: 0px;background-color: #f9f9f9;" :to="'/panel/kursy'">
              <span class="material-symbols-outlined align-middle fw-600 icon">procedure</span>
              Procedury
            </b-list-group-item>


          </b-list-group>

        </b-col>

        <b-col cols="12" lg="10" class="p-3 pt-4">

          <b-form-input class="w-100" v-on:keyup.enter="search" v-model="criteria" placeholder="Szukaj po danych: id, e-mail, nazwisko, nazwa firmy, tax id, numer telefonu ..."></b-form-input>

          <b-row class="d-flex">

            <b-table
                striped
                hover
                responsive
                outlined
                small
                class="mt-3"
                head-variant="light"
                :items="items"
                :fields="fields"
            >

              <template #cell(id)="data">
                <router-link :to="'/panel/payment/' + data.item.id + '/' + data.item.control" target="_blank"><small>{{ data.item.id }}</small></router-link>
              </template>

              <template #cell(user_id)="data">
                <small>{{ data.item.user_id }}</small>
              </template>

              <template #cell(amount)="data">
                <small>{{ data.item.amount }} PLN</small>
              </template>

              <template #cell(item)="data">
                <small>{{ getItemName(data.item.item) }}</small>
              </template>

              <template #cell(date_time)="data">
                <small>
                  <small>
                    Zlecenie: {{ data.item.date_time }}<br/>
                    Potwierdzenie: {{ data.item.confirmed_at }}<br/>
                    Ważność: {{ data.item.valid_to }}
                  </small>
                </small>
              </template>

              <template #cell(t_id)="data">
                <small>
                  {{ data.item.control }} <br/>
                  {{ data.item.t_id }} <br/>
                  {{ data.item.method }} <br/>
                </small>
              </template>

            </b-table>

          </b-row>

        </b-col>

      </b-row>

    </div>

    <Footer/>

  </div>

</template>

<script>

import Footer from '@/elements/Footer';
import NavBar from "@/elements/Navbar/index.vue";

export default {

  name: 'Panel',

  components: {
    NavBar, Footer
  },

  data() {

    return {
      context: null,
      user: {
        id: null,
        permissions: []
      },
      criteria: '',
      items: [],
      fields: [
        { key: 'id', label: 'PaymentID' },
        { key: 'user_id', label: 'UserID' },
        { key: 'amount', label: 'Kwota' },
        { key: 'item', label: 'Produkt', thStyle: { width: '150px !important' } },
        { key: 'date_time', label: 'Daty', thStyle: { width: '250px !important' } },
        { key: 'status', label: 'Status' },
        { key: 't_id', label: 'ID Transakcji' }
      ]
    }

  },

  created() {

    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions

        this.axios.get(window.API_URL + "manager/getContext", { withCredentials: true }).then((response) => {

          this.context = response.data.context

          this.search()

        })

      }

    });

  },

  methods: {

    search: function (){

      this.axios.post(window.API_URL + "deliveries/search", { 'criteria': this.criteria}, { withCredentials: true }).then((response) => {

        this.items = (response.data)

      })

    },

    getItemName: function (itemJson) {

      let item = JSON.parse(itemJson);

      return item.name;

    }

  }

}

</script>